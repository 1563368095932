import { createContext, createRef, PropsWithChildren, RefObject, useContext } from "react";
import { Viewport as PixiViewport } from "pixi-viewport";

const viewport = createRef<PixiViewport>();
const ViewportContext = createContext<RefObject<PixiViewport>>(viewport);

/**
 * Create a provider to distribute references to the PixiJS viewport
 * @param props Component properties.
 * @param props.children Child elements for the component.
 * @returns A viewport provider.
 */
export const ViewportProvider = ({ children }: PropsWithChildren) => {

  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  )
}

/**
 * A shorthand accessor for the viewport provider context.
 * @returns A viewport reference.
 */
export const useViewportContext = () => useContext(ViewportContext);
