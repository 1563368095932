import { formatISO } from "date-fns";
import { apiClient } from "../../apiClient"
import { ApiListResponse } from "../../types";
import { PatientWithEncounter } from "../types";

export type ApiGetPatientWithEncountersResponse = ApiListResponse<PatientWithEncounter>;
export type ApiGetPatientWithEncountersRequest = {
  encounterStartDate?: Date,
  pageQueryId?: string | null,
  page?: number | null,
};

export const apiGetPatientWithEncounters = async (
  request: ApiGetPatientWithEncountersRequest
): Promise<ApiGetPatientWithEncountersResponse> => {
  const searchParams = new URLSearchParams();

  if (request.encounterStartDate != null) {
    searchParams.append('encounterStartDate',  formatISO(request.encounterStartDate, { representation: "date" }));
  }

  if (request.pageQueryId != null) {
    searchParams.append('pageQueryId', request.pageQueryId);
  }

  if (request.page != null) {
    searchParams.append('page', request.page.toString());
  }

  const path = `/patientencounters?${searchParams.toString()}`
  const response = await apiClient.get(path);
  return response.data;
}
