import { Table, Select, Label } from "nhsuk-react-components"
import useAsyncEffect from "use-async-effect";
import { ApiGetPatientWithEncountersResponse, apiGetPatientWithEncounters } from "../api/resources/patientEncounter/getPatientWithEncounters";
import { useState } from 'react';
import { WhiteboardPageState } from "./WhiteboardPage";
import { format } from 'date-fns'
import { Modal } from '../components/common/Modal';
import { useNavigate } from "react-router-dom";
import { ErrorCard } from "../components/common/ErrorCard";
import { AnchorButton } from "../components/common/AnchorButton";
import { PatientWithEncounter } from "../api/resources/types";
import { LoadingSpinner } from "../components/common/LoadingSpinner";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { usePaginatedQuery } from "../lib/hooks/usePaginatedQuery";
import { EmptyTable } from "../components/common/EmptyTable";
import { getDrawingLayouts } from "../lib/editor/drawingLayouts";
import { DateFilter } from "../components/filters/DateFilter";
import './PatientSelectionPage.scss';
import { PatientPageState } from "../components/layouts/AppSimpleLayout";

export const PatientSelectionPage = () => {
  const [encounterDateFilter, setEncounterDateFilter] = useState<Date | undefined>(new Date()); // Default to today's date.

  const [shouldLayoutSelectionModalBeOpen, setShouldTemplateSelectionModalBeOpen] = useState<boolean>(false);

  const [selectedLayoutFileName, setSelectedLayoutFileName] = useState<string>('');
  const [selectedPatientWithEncounter, setSelectedPatientWithEncounter] = useState<PatientWithEncounter | undefined>(undefined);

  const navigate = useNavigate();

  const { load, reload, nextPage, hasNextPage, loading, error, data } = usePaginatedQuery<
    { forcedEncounterDateFilter?: Date | null },
    ApiGetPatientWithEncountersResponse['data'][0],
    ApiGetPatientWithEncountersResponse
  >({
    willLoadInitially: true,
    requestFn: async (args, paginationParams) => {
      let encounterStartDateToUse: Date | undefined; // = args?.forcedEncounterDateFilter ?? encounterDateFilter;
      if (args?.forcedEncounterDateFilter === undefined) {
        // Forced encounterDateFilter not provided, use the previously set encounterDateFilter
        encounterStartDateToUse = encounterDateFilter;
      } else if (args.forcedEncounterDateFilter || args.forcedEncounterDateFilter === null) {
        // Forced encounterDateFilter provided as a Date value or as null
        encounterStartDateToUse = args.forcedEncounterDateFilter || undefined;
      }

      return await apiGetPatientWithEncounters({
        encounterStartDate: encounterStartDateToUse,
        page: paginationParams.page,
        pageQueryId: paginationParams.pageQueryId
      });
    },
  });

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage(),
    onLoadMore: nextPage,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
    delayInMs: 2000,
  });

  useAsyncEffect(async () => {
    await load({ forcedEncounterDateFilter: encounterDateFilter });
  }, []);

  /**
   * Guard against {@link goToWhiteboard} with invalid state.
   *
   * @returns `true` if can go, otherwise `false`
   */
  const canGoToWhiteboard = (): boolean => {
    return !!selectedPatientWithEncounter && !!selectedLayoutFileName;
  }

  /**
   * Navigate to /whiteboard when all the required selections are made.
   */
  const goToWhiteboard = (): void => {
    if (!selectedLayoutFileName || !selectedPatientWithEncounter) {
      // Not ready to go to whiteboard.
      // - This will also be guarded via {@link canGoToWhiteboard} by disabling this action.
      return;
    }

    const patientPageState: PatientPageState = {
      patient: selectedPatientWithEncounter.patient
    }

    const whiteboardPageState: WhiteboardPageState = {
      patientResourceId: selectedPatientWithEncounter.patient.resourceId,
      encounterResourceId: selectedPatientWithEncounter.encounter.resourceId,
      layoutFileName: selectedLayoutFileName
    }

    navigate("/whiteboard", { state: { ...whiteboardPageState, ...patientPageState } });
  }

  const onLayoutChanged = (e : React.FormEvent<HTMLSelectElement>): void => {
    setSelectedLayoutFileName(e.currentTarget.value);
  }

  const onPatientSelected = (patientWithEncounter: PatientWithEncounter): void => {
    setSelectedPatientWithEncounter(patientWithEncounter);

    // Show the layout selection modal
    setShouldTemplateSelectionModalBeOpen(true);
  }

  const onLayoutSelectionModalClosed = (): void => {
    setShouldTemplateSelectionModalBeOpen(false);

    // Clear out the previously selected layout
    setSelectedLayoutFileName('');
  }

  const onEncounterDateFilterApplied = async (newAppliedDate: Date | null): Promise<void> => {
    setEncounterDateFilter(newAppliedDate || undefined);
    await load({ forcedEncounterDateFilter: newAppliedDate });
  }

  const canApplyFilter = (): boolean => {
    return !loading;
  }

  const onRetryAfterFailure = async (): Promise<void> => {
    await reload();
  }

  return (
    <>
      <Table.Panel heading="Patients">
        <DateFilter
          label="Filter by encounter date"
          appliedDate={encounterDateFilter}
          canApplyIfInputIsValid={canApplyFilter()}
          onApply={onEncounterDateFilterApplied}
        />

        {error && !loading ? (
          <ErrorCard
            message="We could not load the list of patients. Please try again."
            onRetry={onRetryAfterFailure}
          />
        ) : null}

        {data.length || loading ? (
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell>Full Name</Table.Cell>
                <Table.Cell>Birth Date</Table.Cell>
                <Table.Cell>Patient ID</Table.Cell>
                <Table.Cell>Encounter ID</Table.Cell>
                <Table.Cell>Encounter Date</Table.Cell>
                <Table.Cell>Action</Table.Cell>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {data.map((patientWithEncounter) => (
                <Table.Row key={patientWithEncounter.encounter.resourceId}>
                  <Table.Cell>{patientWithEncounter.patient.fullName}</Table.Cell>
                  <Table.Cell>{patientWithEncounter.patient.birthDate}</Table.Cell>
                  <Table.Cell>{patientWithEncounter.patient.localId}</Table.Cell>
                  <Table.Cell>{patientWithEncounter.encounter.localId}</Table.Cell>
                  <Table.Cell>{format(new Date(patientWithEncounter.encounter.startDate), 'dd/MM/yyyy HH:mm:ss')}</Table.Cell>
                  <Table.Cell>
                    <AnchorButton onClick={() => onPatientSelected(patientWithEncounter)}>
                      Select patient
                    </AnchorButton>
                  </Table.Cell>
                </Table.Row>
              ))}

              {(loading || hasNextPage()) && !error ? (
                <Table.Row key="loading-indicator">
                  <Table.Cell colSpan={6}>
                    <div ref={sentryRef}>
                      <LoadingSpinner
                        aria-label="Loading patients"
                        label={`Loading${data.length ? ' more ' : ' '}patients...`}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : null}

              {!loading && error ? (
                <Table.Row key="error-notice">
                  <Table.Cell colSpan={6}>
                    <div className="patient-selection-page--table--error-notice">
                      <p className="patient-selection-page--table--error-notice--label">
                        Could not load more patients.
                      </p>
                      <AnchorButton onClick={onRetryAfterFailure}>
                        Try again
                      </AnchorButton>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </Table.Body>
          </Table>
        ) : null}

        {!loading && !data.length && !error ? (
          <EmptyTable message="No patients with an open encounter were found for the selected date." />
        ) : null}
      </Table.Panel>

      <Modal
        open={shouldLayoutSelectionModalBeOpen}
        onClose={onLayoutSelectionModalClosed}
        primaryAction={{
          label: 'Continue',
          onClick: goToWhiteboard,
          disabled: !canGoToWhiteboard()
        }}
      >
        <Label size="m">Select a layout for use as a template:</Label>
        <Select
          value={selectedLayoutFileName}
          onChange={onLayoutChanged}
          style={{ width: '100%' }}
        >
          <Select.Option value=""></Select.Option>
          {getDrawingLayouts().map(
            (drawingLayout) => (
              <Select.Option key={drawingLayout.fileName} value={drawingLayout.fileName}>
                {drawingLayout.name}
              </Select.Option>
            )
          )}
        </Select>
      </Modal>
    </>
  )
}
