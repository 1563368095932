import { PointerEvent } from "react";
import { Point } from "../geometry/Point";
import { Viewport as PixiViewport } from "pixi-viewport";

/**
 * Returns the actual {@link Point} of the mouse for the canvas pointer {@link event}.
 * - This handles scenarios where the Canvas element is offset in the page, e.g, due to a header element.
 *
 * @param event - The pointer event from the {@link HTMLCanvasElement} that we want to determine the actual mouse point.
 */
export const pointerCoordinateFromEvent = (event: PointerEvent<HTMLCanvasElement>, viewport: PixiViewport): Point => {
  const { x, y } = event.currentTarget.getBoundingClientRect();
  const { transform: { worldTransform: { d, tx, ty } = { d: 1, tx: 0, ty: 0 } } } = viewport;

  return {
    x: (event.pageX - x - tx) / d,
    y: (event.pageY - y - ty) / d,
  }
}
