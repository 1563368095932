import { MsalProvider } from '@azure/msal-react';
import { Router } from "./pages/Router";
import AirbrakeErrorBoundary from './components/common/AirbrakeErrorBoundary';
import { cachedMsalInstance } from './lib/auth/cachedMsalInstance';
import { ViewportProvider } from './components/ViewportProvider';

const msalInstance = cachedMsalInstance();

const App = () => {
  return (
    <AirbrakeErrorBoundary>
      <MsalProvider instance={msalInstance}>
        <ViewportProvider>
          <Router />
        </ViewportProvider>
      </MsalProvider>
    </AirbrakeErrorBoundary>
  )
}

export default App;
