import { KeyboardEvent, PointerEvent } from "react";
import { Tool } from "./Tool";
import type { BaseToolAction, ToolCursorType } from "./Tool.types";
import { Point } from "../../geometry/Point";
import { ToolControlMap } from "./ToolControl.types";
import { Graphics as PixiGraphics } from "pixi.js";
import { Key } from "ts-key-enum";
import cloneDeep from "lodash.clonedeep";
import { pointerCoordinateFromEvent } from "../pointerCoordinateFromEvent";

export type PenToolId = 'pen';

export type PenToolProperties = {
  strokeColor: string;
  strokeSize: number;
}

export type ModifiablePenToolProperties = Pick<PenToolProperties, 'strokeColor' | 'strokeSize'>;

export interface PenToolAction extends BaseToolAction {
  toolId: PenToolId;
  values: {
    points: Point[];
    properties: PenToolProperties;
  }
}

/**
 * @deprecated This tool is now deprecated, and you should consider using the {@link LineTool} instead.
 * - Optional consideration to the developer seeing this note: Before removing this tool you should consider copying the
 * ability to do anchored lines from this tool across to the LineTool by adding a toggleable "Anchored" option to it.
 * To draw anchored lines, you will click anywhere on the screen to add individual line points and press "Esc" to close
 * out the path (you will also need to consider the touch interface for this).
 */
export class PenTool extends Tool<PenToolProperties, ModifiablePenToolProperties> {
  override id: PenToolId = "pen";
  override type = 'shape';
  override name = 'Pen';
  override cursor: ToolCursorType = 'crosshair';

  override properties: PenToolProperties = {
    strokeColor: 'red',
    strokeSize: 4
  }

  override controlMap: ToolControlMap<ModifiablePenToolProperties> = {
    strokeColor: {
      type: "choices",
      options: [
        { label: 'Red', value: 'red' },
        { label: 'Green', value: 'green' },
        { label: 'Blue', value: 'blue' },
        { label: 'Orange', value: 'orange' },
      ]
    },
    strokeSize: {
      type: 'choices',
      options: [
        { label: 'S', value: '4' },
        { label: 'M', value: '8' },
        { label: 'L', value: '12' },
        { label: 'XL', value: '16' },
      ]
    },
  }

  override propertySetters: SetterFns<ModifiablePenToolProperties> = {
    setStrokeColor: (strokeColor) => {
      this.properties.strokeColor = strokeColor;
    },

    setStrokeSize: (strokeSize) => {
      this.properties.strokeSize = strokeSize;
    }
  };

  isDrawing: boolean = false;
  points: Point[] = [];

  onKeyUp(event: KeyboardEvent<HTMLCanvasElement>): void {
    this.isDrawing = false;
    this.points = [];

    if (event.key === Key.Escape) {
      this.editor.brushGraphics.clear();
    }
  }

  onPointerMove(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.isDrawing || !this.editor.brushGraphics || !this.points.length) {
      return;
    }

    this.points[1] = pointerCoordinateFromEvent(event, this.viewport);

    PenTool.draw(this.editor.brushGraphics, this.points, this.properties);
  }

  onPointerUp(event: PointerEvent<HTMLCanvasElement>): void {
    if (this.points.length === 2) {
      this.editor.store.appendActionToHistory(
        this.recordAction({
          points: this.points,
          properties: this.properties
        })
      );

      this.points = [];
    }

    this.points[0] = pointerCoordinateFromEvent(event, this.viewport);
  }

  onPointerDown(event: PointerEvent<HTMLCanvasElement>): void {
    this.isDrawing = true;
  }

  onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.pointerGraphics) {
      return;
    }

    this.editor.pointerGraphics.clear();
    this.editor.pointerGraphics.beginFill(this.properties.strokeColor);

    const { x, y } = pointerCoordinateFromEvent(event, this.viewport);
    this.editor.pointerGraphics.drawCircle(x, y, this.properties.strokeSize / 2);
  }

  static draw(graphics: PixiGraphics, points: Point[], properties: PenToolProperties) {
    const previousPoint = points[0];
    const nextPoint = points[1];

    graphics.clear();
    graphics.lineStyle({
      width: properties.strokeSize,
      color: properties.strokeColor
    });

    graphics.moveTo(previousPoint.x, previousPoint.y);
    graphics.lineTo(nextPoint.x, nextPoint.y);
  }

  private recordAction(values: PenToolAction['values']): PenToolAction {
    return {
      toolId: this.id,
      values: cloneDeep(values)
    }
  }
}

/**
 * A guard to check whether or not the given {@link tool} is a {@link PenTool}.
 *
 * @param tool - The tool to check.
 * @returns `true` if {@link tool} is a {@link PenTool}, otherwise `false`.
 */
export const isPenTool = (tool: Pick<Tool, 'id'>): tool is PenTool => {
  return tool.id === 'pen';
}
