import { PointerEvent } from "react";
import { Tool } from "./Tool";
import type { BaseToolAction, ToolCursorType } from "./Tool.types";
import { Point } from "../../geometry/Point";
import { midPointBetween } from "../../geometry/midPointBetween";
import { ToolControlMap } from "./ToolControl.types";
import { Graphics as PixiGraphics } from "pixi.js";
import cloneDeep from "lodash.clonedeep";
import { pointerCoordinateFromEvent } from "../pointerCoordinateFromEvent";
import { applyHistoryToGraphics } from "../applyHistoryToGraphics";
import { renderTexture } from "../../../components/Whiteboard";

export type EraserToolId = 'eraser';

export type EraserToolProperties = {
  brushColor: string;
  brushSize: number;
  erasedAny: boolean;
}

export type ModifiableEraserToolProperties = {}

export interface EraserToolAction extends BaseToolAction {
  toolId: EraserToolId;
  values: {
    segments: Point[];
    properties: EraserToolProperties;
  }
}

export class EraserTool extends Tool<EraserToolProperties, ModifiableEraserToolProperties> {
  override id: EraserToolId = "eraser";
  override type = 'freeform';
  override name = 'Eraser';
  override cursor: ToolCursorType = 'crosshair';

  override properties: EraserToolProperties = {
    brushColor: '#000000',
    brushSize: 2,
    erasedAny: false,
  }

  override controlMap: ToolControlMap<ModifiableEraserToolProperties> = {}

  override propertySetters: SetterFns<ModifiableEraserToolProperties> = {};

  isPressing: boolean = false;
  isDrawing: boolean = false;
  segments: Point[] = [];

  onPointerMove(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.brushGraphics) {
      return;
    }

    const pointerCoordinate = pointerCoordinateFromEvent(event, this.viewport);

    if (this.isPressing) {
      this.isDrawing = true;
      this.segments.push(pointerCoordinate);
    } else {
      this.isDrawing = false;
    }

    if (this.isDrawing) {
      this.segments.push(pointerCoordinate);
      EraserTool.draw(this.editor.brushGraphics, this.segments, this.properties);
    }
  }

  onPointerUp(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = false;
    this.isDrawing = false;

    const action = this.recordAction({
      segments: this.segments,
      properties: this.properties
    });

    this.editor.store.appendActionToHistory(action);

    this.editor.textGraphics?.removeChildren();
    this.editor.textGraphics?.clear();

    this.editor.brushGraphics.clear();
    this.app!.renderer.render(this.editor.brushGraphics, { renderTexture, clear: true });

    // Re-renders the entire history to omit erased elements
    applyHistoryToGraphics(
      this.editor.store.getHistoryUntilCurrentPoint(),
      this.editor.brushGraphics,
      this.editor.textGraphics,      () => {
        this.app!.renderer.render(this.editor.brushGraphics, { renderTexture, clear: false });
      },
    );

    // Removes the newly-made erase operation if it doesn't actually erase anything
    if (!action.values.properties.erasedAny) {
      this.editor.store.removeActionFromHistory();
    }

    // Clear the last drawing segments
    this.segments = [];
  }

  onPointerDown(event: PointerEvent<HTMLCanvasElement>): void {
    this.isPressing = true;

    this.segments.push(pointerCoordinateFromEvent(event, this.viewport));
  }

  onPointerIndicatorDraw(event: PointerEvent<HTMLCanvasElement>): void {
    if (!this.editor.pointerGraphics) {
      return;
    }

    this.editor.pointerGraphics.clear();
    this.editor.pointerGraphics.lineStyle(1, this.properties.brushColor);

    const { x, y } = pointerCoordinateFromEvent(event, this.viewport);
    this.editor.pointerGraphics.drawRect(
      x - this.properties.brushSize / 2,
      y - this.properties.brushSize / 2,
      this.properties.brushSize,
      this.properties.brushSize
    );
  }

  static draw(graphics: PixiGraphics, segments: Point[], properties: EraserToolProperties) {
    graphics.clear();

    let firstPoint = segments[0];
    let secondPoint = segments[1];

    if (!firstPoint) {
      return;
    }

    if (!secondPoint) {
      graphics.beginFill(properties.brushColor, 0.5);
      graphics.drawCircle(firstPoint.x, firstPoint.y, properties.brushSize / 2);
      graphics.endFill();
      return;
    }

    graphics.lineStyle({ width: properties.brushSize, color: properties.brushColor });
    graphics.moveTo(secondPoint.x, secondPoint.y);
    for (let i = 1; i < segments.length; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point

      const midPoint = midPointBetween(firstPoint, secondPoint);
      graphics.quadraticCurveTo(
        firstPoint.x,
        firstPoint.y,
        midPoint.x,
        midPoint.y
      );

      firstPoint = segments[i];
      secondPoint = segments[i + 1];
    }

    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    graphics.lineTo(firstPoint.x, firstPoint.y);
  }

  private recordAction(values: EraserToolAction['values']): EraserToolAction {
    return {
      toolId: this.id,
      values: cloneDeep(values)
    }
  }
}

/**
 * A guard to check whether or not the given {@link tool} is a {@link EraserTool}.
 *
 * @param tool - The tool to check.
 * @returns `true` if {@link tool} is a {@link EraserTool}, otherwise `false`.
 */
export const isEraserTool = (tool: Pick<Tool, 'id'>): tool is EraserTool => {
  return tool.id === 'eraser';
}
