import { ApiPaginationParams } from "../../api/types";

export const parsePaginationParams = (url: string | null): ApiPaginationParams => {
  if (!url) {
    return emptyPaginationParams();
  }

  const searchParamsString = url.split('?')[1];
  const searchParams = new URLSearchParams(searchParamsString);

  const pageQueryId = searchParams.get('pageQueryId') ?? null;
  const page = Number.parseInt(searchParams.get('page') ?? '') || null;

  return {
    page: page,
    pageQueryId
  }
}

export const emptyPaginationParams = () => {
  return {
    page: null,
    pageQueryId: null
  }
}
